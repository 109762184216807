import React, { useState, useEffect, useRef } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import LinkMap from "../components/linkMap";
import NextArticle from "../components/nextArticle";
import Cta from "../components/Cta";

import styled from "styled-components";
import Container from "../components/Container";
import Header from "../components/header";
import Sticky from "react-stickynode";
import { css } from "emotion";
import useMatchMedia from "../components/useMatchMedia";

const Content = styled.div`
  flex: 1;
  margin: 0;
  display: flex;
  justify-content: space-between;

  p,
  li {
    font-size: 20px;
  }

  a {
    color: #4c3e9c;
  }

  img {
    margin: 1rem 0;
  }
`;

const MainHeading = styled.h1`
  text-align: left;
  font-size: 44px;
  margin-bottom: 1rem;
`;

const StyledSubHeader = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`;

const StyledZIndex = styled.div`
  z-index: 2;
`;

const ContentRestriction = styled.div`
  max-width: 732px;
`;

const StyledStooper = styled.div``;

export default ({ data }) => {
  const post = data.markdownRemark;
  const [limiterHeight, setlimiterHeight] = useState(100);
  const limitRer = useRef(null);
  const desktop = useMatchMedia("(min-width: 1080px)");

  // useEffect(() => {
  //   setTimeout(() => {
  //     setlimiterHeight(limitRer.current.getBoundingClientRect().top - 600);
  //   }, 1000);
  // }, []);

  return (
    <Layout>
      <StyledSubHeader>
        <Container>
          <Header dark></Header>
        </Container>
      </StyledSubHeader>
      {/* {desktop && (
        <Sticky top={0} bottomBoundary={limiterHeight}>
          <LinkMap current={post.frontmatter.title} />
        </Sticky>
      )} */}
      <Container>
        <MainHeading>{post.frontmatter.title}</MainHeading>
        <Content>
          <ContentRestriction dangerouslySetInnerHTML={{ __html: post.html }} />
        </Content>
      </Container>
      <StyledStooper ref={limitRer} id="sticky-end"></StyledStooper>
      <NextArticle id={post.id} />
      <Cta></Cta>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      id
      frontmatter {
        title
      }
    }
  }
`;
